import { Row, Col, Typography, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { getAllInspections } from "../network_requests";
const { Text } = Typography;

const Inspections = (props) => {
  const { state, setNewInspection, setEditInspection } = props;
  const [inspections, setInspections] = useState([]);

  useEffect(async () => {
    const inspections_ = await getAllInspections(state?.stkey);
    setInspections(inspections_?.list);
  }, [props.update]);

  return (
    <>
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
        }}
      >
        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            <IntlMessages id="Name" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            <IntlMessages id="Last Check" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            <IntlMessages id="Next Check" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            <IntlMessages id="Approved By" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            <IntlMessages id="Inspected by" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            <IntlMessages id="Condition" />
          </Text>
        </Col>
      </Row>
      {inspections?.map((inspection) => {
        return (
          <Row
            style={{
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={() => {
              setEditInspection({ ...inspection });
            }}
          >
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {inspection?.cfeld1}
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {inspection?.nfeld1}
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {inspection?.cfeld2}
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {inspection?.cfeld2}
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {inspection?.cfeld5}
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {inspection?.nfeld2}
              </Text>
            </Col>
          </Row>
        );
      })}
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          onClick={() => {
            setNewInspection({ ...state });
          }}
        >
          add Inspection
        </Button>
      </Row>
    </>
  );
};

export default Inspections;
