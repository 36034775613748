import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Form,
  Input,
  Popover,
  Popconfirm,
  Modal,
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";

const { Text } = Typography;

export default function Imprint(props) {
  const [load, setLoad] = useState(false);

  useEffect(async () => {
    setLoad(true);

    setLoad(false);    
  }, []);

  return (
  <>
  {load ? (
    <div
      style={{
        marginTop: "10vh",
        justifyContent: "center",
        display: "flex",
        width: "100%",
      }}
    >
      <Spin tip="Loading..." />
    </div>
  ) : null}
  {!load ? (
    <div>
        <h1><IntlMessages id="imprint.1" /></h1><br /><br />
        <IntlMessages id="imprint.paragraph" /><br /><br />
        <IntlMessages id="imprint.addy1" /><br />
        <IntlMessages id="imprint.addy2" /><br />
        <IntlMessages id="imprint.addy3" /><br /><br />
        <IntlMessages id="imprint.addy4" /><br />
        <IntlMessages id="imprint.addy5" /><br />
        <IntlMessages id="imprint.addy6" /><br />
        <IntlMessages id="imprint.addy7" /><br />
        <IntlMessages id="imprint.addy8" /><br />
        <IntlMessages id="imprint.addy9" /><br />
        <IntlMessages id="imprint.addy10" /><br /><br />
        <IntlMessages id="imprint.register1" /><br />
        <IntlMessages id="imprint.register2" /><br />
        <IntlMessages id="imprint.register3" /><br />
        <IntlMessages id="imprint.register4" /><br />
        <IntlMessages id="imprint.register5" /><br />
        <IntlMessages id="imprint.register6" /><br />
        <IntlMessages id="imprint.register7" /><br /><br />
        <IntlMessages id="imprint.aufsicht1" /><br />
        <IntlMessages id="imprint.aufsicht2" /><br />
        <IntlMessages id="imprint.aufsicht3" /><br />
        <IntlMessages id="imprint.aufsicht4" /><br /><br />
        <h2><IntlMessages id="imprint.haftungsausschluss1" /></h2><br /><br />
        <IntlMessages id="imprint.haftungsausschluss2" /><br />
        <IntlMessages id="imprint.haftungsausschluss3" /><br /><br />
        <IntlMessages id="imprint.haftungsausschluss4" /><br />
        <IntlMessages id="imprint.haftungsausschluss5" /><br /><br />
        <IntlMessages id="imprint.haftungsausschluss6" /><br />
        <IntlMessages id="imprint.haftungsausschluss7" /> <br /><br />
        </div>
  ) : null }
</>
);
}
