import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Dashboard from "./dashboard";
import Layouts from "./Layouts";
import asyncComponent from "../../util/asyncComponent";
import UserOverview from "./overview-indoor/userManagement";
import Overview from "./overview-indoor/overview";
import List from "./overview-indoor/listValues";
import Ashtrays from "./overview-outdoor/ashtrays";
import DogBags from "./overview-outdoor/dog-bags";
import Stations from "./overview-outdoor/stations";
import TrashTrays from "./overview-outdoor/trashtrays";
import Cockpit from "./cockpit";
import PruefCockpit from "./pruefCockpit";
import Trees from "./overview-outdoor/trees";
import Search from "./search";
import SearchResult from "./searchResult";
import Link from "./Link";
import LinkOverview from "./LinkOverview";
import WhitelabeledDevice from "./WhitelabeledDevice";
import ChangePassword from "./ChangePassword";
import SearchStatistic from "./searchStatistic";
import Hydrants from "./overview-outdoor/hydrant";
import AutomaticWaterings from "./overview-outdoor/automatic_watering";
import Fountains from "./overview-outdoor/fountain";
import OtherWaterConnectors from "./overview-outdoor/other_water_connectors";
import PumpingStations from "./overview-outdoor/pumping_stations";
import Error500 from "../customViews/errorPages/500"
import Imprint from "./imprint"
import PrivacyStatement from "./privacyStatement"

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/user-management`} component={ ()=><UserOverview/> } />
    <Route path={`${match.url}/link`} component={()=><Link/>} />
    <Route path={`${match.url}/linkOverview`} component={ ()=><LinkOverview/> } />
    <Route path={`${match.url}/whitelabeledDevice`} component={ ()=><WhitelabeledDevice/> } />
    <Route path={`${match.url}/changePassword`} component={ ()=><ChangePassword/> } />
    <Route path={`${match.url}/error`} component={ ()=><Error500/> } />
    <Route path={`${match.url}/imprint`} component={ ()=><Imprint/> } />
    <Route path={`${match.url}/privacyStatement`} component={ ()=><PrivacyStatement/> } />
   {/* <Route path={`${match.url}/overview`} component={Overview} />
    <Route path={`${match.url}/list-overviews`} component={List} />
    <Route path={`${match.url}/ashtrays`} component={Ashtrays} />
    <Route path={`${match.url}/dog-bag`} component={DogBags} />
    <Route path={`${match.url}/stations`} component={Stations} />
    <Route path={`${match.url}/trashtrays`} component={TrashTrays} />
    <Route path={`${match.url}/trees`} component={Trees} />
    <Route path={`${match.url}/hydrant`} component={Hydrants} />
    <Route
      path={`${match.url}/automatic_watering`}
      component={AutomaticWaterings}
    />
    <Route path={`${match.url}/fountain`} component={Fountains} />
    <Route
      path={`${match.url}/other_water_connectors`}
      component={OtherWaterConnectors}
    />
    <Route path={`${match.url}/pumping_stations`} component={PumpingStations} />
    <Route path={`${match.url}/cockpit`} component={Cockpit} />
<Route path={`${match.url}/pruefCockpit`} component={PruefCockpit} /> *
    <Route path={`${match.url}/search`} component={Search} />
    <Route path={`${match.url}/searchResult`} component={SearchResult} /> /}
    {/* <Route path={`${match.url}/searchStatistic`} component={SearchStatistic}/> */}
    {/* <Route path={`${match.url}/dashboard`} component={Dashboard}/> */}
    {/*  <Route path={`${match.url}/widgets`} component={Widgets}/>
    <Route path={`${match.url}/metrics`} component={Metrics}/>
    <Route path={`${match.url}/layouts`} component={Layouts}/>
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/> */}
  </Switch>
);

export default Main;
