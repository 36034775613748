import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Table,
  Popover,
  Popconfirm,
  Card,
  Badge,
} from "antd";
import { deleteUser, getUsers, getUserGroups } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import AddUser from "./addUser";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";

const { Text } = Typography;

export default function User(props) {
  const [User, setUser] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const intl = useIntl();

  useEffect(async () => {
    setLoad(true);
    const User_ = await getUsers((page - 1) * 10);

    if (User_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } else if (User_?.status === 403) {
      history.push({
        pathname: '/main/error'
      });    
      return; 
    }
    setUser(User_);   
    setCount(User_?.length ? User_?.length : 0);
    setLoad(false);

  }, [page, updateData]);

  async function confirm(e) {
    
    setLoad(true);

    const delResult = await deleteUser(e);
    
    const User_ = await getUsers((page - 1) * 10);

    if (User_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } else if (User_?.status === 403) {
      
    }
    setUser(User_);   
    setCount(User_?.length ? User_?.length : 0);

    setLoad(false);
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: intl.formatMessage({ id: "app.userAuth.username" }),
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => {  return a.username.localeCompare(b.username); },
    },
    {
      title: intl.formatMessage({ id: "app.userAuth.role" }),
      dataIndex: 'role',
      key: 'role',
      render: text => <Text >{text.replace("ROLE_", "")}</Text>,
      sorter: (a, b) => {  return a.role.localeCompare(b.role); },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      render: text => text ? <Badge status="success" /> : <Badge status="error" />,
    },  {
      title: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <span>

      <Popconfirm title={intl.formatMessage({ id: "user.delete" })} onConfirm={() => {
      confirm(text.id);
    }} okText={intl.formatMessage({ id: "confirm.yes" })} cancelText={intl.formatMessage({ id: "confirm.no" })}>
        <span className="gx-link"><i className="icon icon-trash"/></span>
      </Popconfirm>

        </span>
      ),
    }
  ];

  return (
    <>
      <AddUser
        open={addUser}
        setOpen={setAddUser}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        groups={userGroups}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      ) : null}
      {!load ? (
        <>
         <Card className="gx-card" title={intl.formatMessage({ id: "menu.usermanagement" })}>
         <Table className="gx-table-responsive" columns={columns} dataSource={User}/>

          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddUser(!addUser);
              }}
            >
              <IntlMessages id="user.add" />
            </Button>
          </Row>       
          </Card>  
        </>
      ) : null}
    </>
  );
}
