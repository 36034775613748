import {
    Row,
    Col,
    Rate,
    Typography,
} from "antd";
import RateResult from "./rateResult";
import { useHistory } from "react-router";
import { vote, addCall } from "./network_requests";
import React, { useState } from "react";

const { Text } = Typography;

export default function SearchResultRow(props) {
    const { numberOfItems, catType, innerobj, bestMatch } = props;
    const history = useHistory();
    const [rating, setRating] = useState(innerobj?.rating);
    const [rated, setRated] = useState(innerobj?.rated);

    async function onRateResultChange(value) {

        var stkey = getCorrectStKeyField(innerobj);

        const result = await vote( { query: history?.location?.search.substring(1) , itemStKey: stkey, voting: value });    
        if (result?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
        } 

        setRating(result.success);
        setRated(value);
    }

    function getCorrectStKeyField(item) {

        var stkey = item?.stkey !== undefined && item?.stkey.trim() != '' ? item?.stkey : item?.cfeld2;
        return stkey;
    }

      function openDialog(sr) {
          props.onClick(sr);

          var stkey = getCorrectStKeyField(sr);
          addCall( { query: history?.location?.search.substring(1) , itemStKey: stkey, voting: 0 });
      }

    /**
    1 estate
    2 buildings
    3 floors
    4 rooms
    7 machines
    8 med appliances
    9 appliances
     */
    function getCorrectField(sr) {

        switch (sr.stclass) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 8:
            case 9:
                return sr?.cfeld1;
            case 4:
            case 7:
                return sr?.cfeld2;
        }
    }
    return (
        <Row
            style={{
                backgroundColor: "#fff",
                padding: "10px 30px",
                margin: "0",
                borderBottom: (numberOfItems > 1) && "1px solid #eaeaea",
            }}
        >
            <Col
                xs={12}
                sm={12}
                xl={12}
                style={{
                    padding: 0,
                }}
            >
                <Text
                    style={{
                        color: "#024442",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        openDialog(innerobj);
                    }}
                >
                    {getCorrectField(innerobj)}
                </Text>
                <br /><br />

                {catType == "D" &&
                    <Text
                        style={{
                            fontSize: 10
                        }}
                    >
                        Ort:&nbsp;
                  {innerobj?.parentClasses?.map((item, index) => {
                        return (<Text
                            onClick={() => {
                                openDialog(item);
                            }}
                            style={{
                                color: "#024442",
                                fontSize: 10,
                                cursor: "pointer",
                            }}
                        >
                            { getCorrectField(item)}
                            { (index < innerobj?.parentClasses?.length - 1) && ' => '}
                        </Text>
                        )
                    })}  </Text>}


                {catType == "F" && innerobj?.parentClasses?.length > 0 &&
                    <Text style={{ fontSize: 10 }} > gefunden in: &nbsp;
                  {innerobj?.parentClasses?.map((item) => {
                        return (
                            <Text style={{ fontSize: 10, color: "#024442", cursor: "pointer" }} onClick={() => { openDialog(item); }}>
                                { getCorrectField(item)}
                            </Text>
                        );
                    })}
                    </Text>
                }
                { !bestMatch && 
                    <table>
                    <tr><td>Bewertung:&nbsp;&nbsp;&nbsp;</td><td><Rate disabled value={rating} /></td></tr>
                    <tr><td> {rated > 0 && 'bewertet:' } {rated == 0 && 'bewerten:' }&nbsp;&nbsp;&nbsp;</td><td><RateResult value={rated} onChange={onRateResultChange}  />{innerobj?.stkey}</td></tr>
                </table> }
                </Col>
                <Col
                xs={12}
                sm={12}
                xl={12}
                style={{
                    padding: 0,
                }} >
                    {catType == "F" && innerobj?.cfeld3 && <div>
                <Text style={{ fontSize: 10, fontWeight: "bold" }} > gefunden Wöter:  </Text> {innerobj?.cfeld3}
                </div>   
            }       {catType == "F" && innerobj?.cfeld4 && <div>
            <Text style={{ fontSize: 10, fontWeight: "bold" }} > Ausschnitt: </Text> <div dangerouslySetInnerHTML={{ __html: innerobj?.cfeld4  }} />
            </div>   
        }       
            </Col>
        </Row>
    )

}