import moment from "moment";
import axios from "axios";
import L from "leaflet";

const getUrl = () => {
  return "https://iot.lunatone.com:8443/";
  //return "https://iot.lunatone.com:8444/";
  //return "http://localhost:8800/"; 
};

export const url = getUrl(); 

export const locationIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});

export const token = window.localStorage.getItem("user_id");

export const string_to_double = (value) => {
  if (typeof value !== "string") {
    value = value.format("DD.MM.YYYY");
  }

  var date = value.substr(0, 2);
  var month = value.substr(3, 2);
  var year = value.substr(6, 4);
  return parseFloat(year + month + date);
};

export const double_to_string = (val) => {
  var value = val.toString();
  var date = value.substr(6, 2);
  var month = value.substr(4, 2);
  var year = value.substr(0, 4);
  return date + "." + month + "." + year;
};

export const getWarning = (val) => {
  var now = moment(new Date());
  var end = moment(double_to_string(val), "DD.MM.YYYY");
  var duration = moment.duration(now.diff(end));
  var days = duration.asDays();
  return days;
};

const classifyMenu = (menu) => {
  var classifiedMenu = [];
  var arrayItem = [];
  menu?.map((menuItem, index) => {
    arrayItem = [...arrayItem, { ...menuItem }];
    if (menu[index + 1]?.parentkey === "none") {
      classifiedMenu = [...classifiedMenu, [...arrayItem]];
      arrayItem = [];
    }
  });
  classifiedMenu = [...classifiedMenu, [...arrayItem]];
  return classifiedMenu;
};

export const getMenu = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getMenuForUser`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
