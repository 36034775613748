import React from "react";
import {Button, Form, Input} from "antd";
import IntlMessages from "util/IntlMessages";
import {Link} from "react-router-dom";
import { url } from "../constants";
import axios from "axios";
import {message} from "antd/lib/index";
import LanguageMenu from "./LanguageMenu";
import {useSelector} from "react-redux";
import { useIntl } from "react-intl";

const FormItem = Form.Item;

const ForgotPassword = () => {

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    //console.log("Received values of form",values)
    forgotpassword(values.email);
  };

  const {locale} = useSelector(({settings}) => settings);
  const intl = useIntl();

  const forgotpassword = async (email) => {
      try {
        
        const response = await axios.post(
          `${url}forgotpassword/` + locale.locale,
          {
            username: email
          }
        );

        message.info(response.data);
        document.getElementById("forgotPasswordForm").reset();
  
        return response.data;
      } catch (error) {

        message.error(error);
        document.getElementById("forgotPasswordForm").reset();
        return error.response?.data;
      }  
  }

  return (

    <div className="gx-app-login-wrap">
    <div className="gx-app-login-container">
      <div className="gx-app-login-main-content">
        <div className="gx-app-logo-content">
          <div className="gx-app-logo-content-bg">
            
          </div>
          <div className="gx-app-logo-wid">
             <h1><IntlMessages id="app.userAuth.forgotPassword"/></h1>   
             {/*<p><IntlMessages id="app.userAuth.forgot"/></p> */}
          </div>
          <div className="gx-app-logo">
            <img alt="example" src="/frontend/assets/images/logo.png"/>
          </div>
        </div>
        <div className="gx-app-login-content">

        <Form
          initialValues={{ remember: true }}
          id="forgotPasswordForm"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem name="email" rules={[{ required: true, message: 'Please input your E-mail!' , pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }]}>
              <Input className='gx-input-lineheight' type="email" placeholder={intl.formatMessage({ id: "app.userAuth.username" })} />
          </FormItem>

          <FormItem>
          <table border="0">
                  <tr>
                    <td>
            <Button type="primary" className="gx-mb-0" htmlType="submit">
              <IntlMessages id="app.userAuth.send"/>
            </Button>
            </td>
                <td >
                  &nbsp;
                </td>
                <td >
            <Link style={{textDecoration: 'underline'}} to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link><br />
            <Link style={{textDecoration: 'underline'}} to="/signup"><IntlMessages id="appModule.regsiter"/></Link>
            </td>
                </tr>
                </table>
          </FormItem>
          <LanguageMenu/>
        </Form>
        </div>
      </div>
    </div>
    </div>

  );
}

export default ForgotPassword
