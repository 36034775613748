import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Form,
  Input,
  Popover,
  Popconfirm,
  Modal,
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import { makeindex } from "./network_requests";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function Search(props) {
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [fetchData, setFetchData] = useState(false);

  // const { response, loading, error } = useAxios({
  //     method: 'post',
  //     path: 'search/indexdata',
  //     fetchDataNow: fetchData,
  // });

  useEffect(async () => {
    setLoad(true);

    // if (response) {
    //   Modal.success({
    //     title: 'Indexed',
    //     content: response.success,
    //   });
    // }

    setLoad(false);    
  }, []);

  //async 
  async function sendAsync() {
    setLoad(true);
    const result_ = await makeindex();
    if (result_) {
      Modal.success({
        title: 'Indexed',
        content: result_.success,
      });
    }   
    // if (response) {
    //   Modal.success({
    //     title: 'Indexed',
    //     content: response.success,
    //   });
    // }
    setLoad(false);   
  }

  return (
  <>
  {load ? (
    <div
      style={{
        marginTop: "10vh",
        justifyContent: "center",
        display: "flex",
        width: "100%",
      }}
    >
      <Spin tip="Loading..." />
    </div>
  ) : null}
  {!load ? (
        <Button
        size="small"
        type="primary"
        style={{
          margin: 0,
        }}
        onClick={() => {
          //setFetchData(true);
          sendAsync();
        }}
      >
      Indexieren
      </Button> 
      
  ) : null }
</>
);
}
