import { url } from "../../../../constants";
import axios from "axios";

export const getUsers = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.get(
      `${url}getAllUsers`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addUser = async (values, group) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}user/` + values.locale,
      {
        username : values.email,
        password : values.password,
        rolename : values.group
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editUser = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}editUser`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteUser = async (id) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.delete(
      `${url}user/` + id,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUserGroups = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}findAllGroups`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
