import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Form,
  Input,
  Popover,
  Popconfirm,
  Modal,
  Card,
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import { getLink, linkDevice } from "./network_requests";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";

const { Text } = Typography;

export default function Link(props) {
  const [load, setLoad] = useState(false);
  const history = useHistory();
  
  const intl = useIntl();

  // const { response, loading, error } = useAxios({
  //     method: 'post',
  //     path: 'search/indexdata',
  //     fetchDataNow: fetchData,
  // });

  useEffect(async () => {
    setLoad(true);

    const result_ = await getLink();
    console.log(result_);
    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setLoad(false);
  }, []);

  //async 
  async function sendAsync(deviceId, linkKey) {
    setLoad(true);
    const result_ = await linkDevice(deviceId, linkKey);

    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 
    if (result_) {
      if (result_ == "Link success") {
        
        Modal.success({
          title: intl.formatMessage({ id: "LinkDevice" }),
          content: intl.formatMessage({ id: "link.success" }),
        });
      } else if (result_ == "Link error") {
        Modal.error({
          title: intl.formatMessage({ id: "LinkDevice" }),
          content: intl.formatMessage({ id: "link.error" }),
        });
      } else if (result_ == "Link already exists") {
        Modal.error({
          title: intl.formatMessage({ id: "LinkDevice" }),
          content: intl.formatMessage({ id: "link.exists" }),
        });
      } else {
        Modal.info({
          title: intl.formatMessage({ id: "LinkDevice" }),
          content: result_,
        });
      }
            
    }
    setLoad(false);
  }

  const onFinishFailed = (errorInfo) => {
  };

  const onFinish = (values) => {
    sendAsync(values.deviceId, values.linkKey);
    document.getElementById("linkingForm").reset();
  };

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      ) : null}
      {!load ? (
        <>
        <Row>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} >

              <div align="center">
                <Text style={{ fontSize: 24 }}><IntlMessages id="LinkDevice" /></Text> 
              </div>

            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
          </Row>

          <br/>
          <br/>
          <Row>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} >

              <div align="center">
                <Text style={{ fontSize: 16 }}><IntlMessages id="link.description"/></Text> 
              </div>

            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
          </Row>



          <br/>
          <br/>
          <Form
              name="basic"
              id="linkingForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
          <Row>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} >
              <div align="center">
                <Text style={{ fontSize: 18 }}>Device Id</Text>
                <Form.Item
                
                rules={[
                  { required: true, message: intl.formatMessage({ id: "value.needed" }) },
                ]}
                name="deviceId"
              >
                <Input placeholder="Device Id" />
                </Form.Item>
              </div>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
          </Row>      
          <Row>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} >

              <div align="center">
                <Text style={{ fontSize: 18 }}>Link Key</Text>
                <Form.Item
                
                rules={[
                  { required: true, message: intl.formatMessage({ id: "value.needed" }) },
                ]}
                name="linkKey"
              >
                <Input placeholder="Link Key" />
                </Form.Item>
              </div>

            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} >
            <div align="center">
            <Form.Item>
              <Button type="primary" className="gx-mb-0" htmlType="submit">
                Link
              </Button>
              </Form.Item>
              </div>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
          </Row>
          </Form>
        </>


      ) : null}
    </>
  );
}
