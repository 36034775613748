import { Modal, Input, Form, Menu, Col, Row, Dropdown, Typography } from "antd";
import { useRef, useState } from "react";
import { addUser } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import {message} from "antd/lib/index";
import {useSelector} from "react-redux";

export default function AddUser(props) {
  const { open, setOpen } = props;
  const { Text } = Typography;
  const intl = useIntl();
  const [group, setGroup] = useState("");

  const ref = useRef();
  const history = useHistory();
  const {locale} = useSelector(({settings}) => settings);

  const menu = (
    <Menu>
      <Menu.Item onClick={(e) => {
              setGroup("ROLE_ADMIN");
            }}>
        <Text>ADMIN</Text>
      </Menu.Item>
      <Menu.Item onClick={(e) => {
              setGroup("ROLE_USER");
            }}>
        <Text>USER</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title={
        <>
          <IntlMessages id="user.add" />
        </>
      }
      width="400px"
      bodyStyle={{width: '400px'}}

      visible={open}
      centered
      destroyOnClose
      okText={intl.formatMessage({ id: "save" })} 
      onOk={() => {        
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="add User"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={async (values) => {
          
          if (group == null || group == undefined || group.trim() == "") {
            message.error("Group neccessary");            
            return;
          }
          if (values.password != values.password2) {
            message.error(intl.formatMessage({ id: "app.userAuth.passwordsDontMatch" }));            
            return;
          }
          
          setOpen(!open);
          props.setLoad(true);

          var clone = { ...values };
          clone.locale = locale.locale;

          const response = await addUser({
            ...clone, group
          });

          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          message.info(response);
          props.setUpdate(!props.update);
          props.setLoad(false);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item label={intl.formatMessage({ id: "app.userAuth.username" })} name="email" style={{ marginBottom: 0 }} rules={[{required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,   message: intl.formatMessage({ id: "app.userAuth.enterUsername" }) }]}>
          <Input />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "app.userAuth.password" })} name="password" style={{ marginBottom: 0 }} rules={[{required: true, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/ , message: intl.formatMessage({ id: "app.userAuth.enterPasswordFormat" }) ,    }]}>
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "app.userAuth.reenterpassword" })} dependencies={['password']} hasFeedback name="password2" style={{ marginBottom: 0 }} 
        rules={[{required: true, message: intl.formatMessage({ id: "app.userAuth.enterPassword" }) },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(intl.formatMessage({ id: "app.userAuth.passwordsDontMatch" })));
          },
        })
        ]}>
          <Input.Password type="password" />
        </Form.Item>
        
        <Form.Item label={intl.formatMessage({ id: "app.userAuth.role" })} style={{ marginBottom: 0 }} rules={[{required: true, message: intl.formatMessage({ id: "app.userAuth.enterPassword" }) }]}  >
          <Dropdown overlay={menu} trigger="click">
            <Row
              style={{
                border: "1px solid #d9d9d9",
                height: "36px",
                borderRadius: "6px",
                margin: "0px",
                display: "flex",
              }}
            >
              <Col span={20} style={{ display: "flex", alignItems: "center" }}>
                <Text>{group}</Text>
              </Col>
              <Col span={2} style={{ display: "flex", alignItems: "center" }}>
                <DownOutlined style={{ color: "#d9d9d9" }} />
              </Col>
            </Row>
          </Dropdown>
        </Form.Item>
      </Form>
    </Modal>
  );
}
