import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import { useHistory } from "react-router";

const UserInfo = () => {

  const dispatch = useDispatch();

  const history = useHistory();
  const routeChange = () =>{ 
    let path = `changePassword`; 
    history.push(path);
  }

  const uname = window.localStorage.getItem("uname");

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={routeChange} ><IntlMessages id="main.account" /></li>
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="main.logout" />
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
              <span className="gx-pointer gx-flex-row gx-align-items-center">
      <i className="icon icon-user-o"/>   
      <span className="gx-pl-2 gx-language-name">{uname}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>     
                  </span>
    </Popover>
  )

}

export default UserInfo;
