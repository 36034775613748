import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  message,
  Dropdown,
  Menu,
  Typography,
  Button,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { addInspection, deleteImage, getFields } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { string_to_double, double_to_string } from "../../../constants";
import AddPhoto from "./AddPhoto";
import { getPictures } from "../../extraComponents/MoreActions/network_requests";

const { Text } = Typography;

const success = () => {
  message.success("Inspection created successfully");
};

const error = () => {
  message.error("Error! Please try again");
};

export default function AddInspection(props) {
  const { open, setOpen, roomAppliance } = props;

  const history = useHistory();
  const ref = useRef();
  const [inspectionDate, setinspectionDate] = useState(
    moment(moment(), "DD.MM.YYYY")
  );
  const [condition, setCondition] = useState(1.0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [image, setImage] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);
  const [openM, setOpenM] = useState(false);
  const [typeValues, setTypeValues] = useState([]);
  const [inspectionType, setInspectionType] = useState(null);

  useEffect(async () => {
    if (props.roomAppliance) {
      const types = await getFields("G01C09D00F01");
      setTypeValues(types);
    }
  }, []);

  const type_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setInspectionType(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {typeValues?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  useEffect(async () => {
    const pictures_ = await getPictures({
      attribute: "none",
      objID: roomAppliance ? open?.stkey : open?.objStkey,
      group: "1",
      stClass: 9,
    });
    setPictures(pictures_);
  }, [open, update]);

  const uploadProps = {
    accept: ".png",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          setFile(e.target.result);
        };
        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="Inspection" />
        </>
      }
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText="Save"
      onCancel={() => {
        setOpen(null);
      }}
      footer={
        <Row>
          <Col xs={4}>
            {roomAppliance ? null : (
              <Button
                onClick={() => {
                  setIsModalVisible(!isModalVisible);
                }}
              >
                Add Photo
              </Button>
            )}
          </Col>
          <Col xs={20} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              key="back"
              onClick={() => {
                setOpen(null);
              }}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setOpen(null);
                ref?.current?.submit();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      }
    >
      <AddPhoto
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        state={open}
        pictures={pictures}
        setPhoto={setPhoto}
        cameraOpen={cameraOpen}
        setCameraOpen={setCameraOpen}
        uploadProps={uploadProps}
        deleteImage={deleteImage}
        open={openM}
        setOpen={setOpenM}
        file={file}
        setFile={setFile}
        image={image}
        setImage={setImage}
        update={update}
        setUpdate={setUpdate}
      />
      <Form
        name="add Inspection"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          inspectionType: open?.checkType,
          condition: 1.0,
          approvedBy: window.localStorage.getItem("uname"),

          // remember: true,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addInspection({
            ...values,
            inspectionDate: string_to_double(inspectionDate),
            stKey: roomAppliance ? open?.stkey : open?.objStkey,
            inspectionType: inspectionType,
          });

          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          if (response.success === "success") {
            success();
          } else {
            error();
          }
          // props.setLoad(false);
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={12} style={{ paddingRight: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.inspectionDate" />}
              name="inspectionDate"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={inspectionDate}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setinspectionDate(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="condition" />}
              name="condition"
              style={{ marginBottom: 0 }}
            >
              <Dropdown
                overlay={
                  <Menu
                    title="Choose field"
                    onClick={(e) => {
                      setCondition(e.key);
                    }}
                    style={{
                      overflowY: "scroll",
                      maxHeight: "250px",
                    }}
                  >
                    <Menu.Item key={1.0}>Ok</Menu.Item>
                    <Menu.Item key={0.0}>Faulty</Menu.Item>
                  </Menu>
                }
                trigger="click"
              >
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{condition == 0 ? "Faulty" : "Ok"}</Text>
                  </Col>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ paddingRight: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.inspectionInspectedBy" />}
              name="approvedBy"
              style={{ marginBottom: 0 }}
            >
              <Input defaultValue={window.localStorage.getItem("uname")} />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.inspectionApprovedBy" />}
              name="inspectedBy"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Inspection Type" />}
              name="inspectionType"
              style={{ marginBottom: 0 }}
            >
              {roomAppliance ? (
                <Dropdown overlay={type_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={20}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{inspectionType}</Text>
                    </Col>
                    <Col
                      span={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              ) : (
                <Input disabled />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="comment"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
