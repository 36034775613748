import React from "react";
import {Button, Form, Input} from "antd";
import IntlMessages from "util/IntlMessages";
import LanguageMenu from "./LanguageMenu";

import {Link} from "react-router-dom";

const PasswordReset = () => {

  return (

    <div className="gx-app-login-wrap">
    <div className="gx-app-login-container">
      <div className="gx-app-login-main-content">
        <div className="gx-app-logo-content">
          <div className="gx-app-logo-content-bg">
            
          </div>
          <div className="gx-app-logo-wid">
             <h1><IntlMessages id="app.userAuth.passwordReset"/></h1>   
          </div>
          <div className="gx-app-logo">
            <img alt="example" src="/frontend/assets/images/logo.png"/>
          </div>
        </div>
        <div className="gx-app-login-content">

        <span><IntlMessages id="app.userAuth.passwordResetText"/></span>
        <br /><br />
        {/*<Link to="/signin">
        <Button type="primary" className="gx-mb-0" >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
        </Link>*/}
        <LanguageMenu/>
        </div>
      </div>
    </div>
    </div>

  );
}

export default PasswordReset
