import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import { useHistory } from "react-router";

const uname = window.localStorage.getItem("uname");

const UserProfile = () => {

  const history = useHistory();
  const routeChange = () =>{ 
    let path = `changePassword`; 
    history.push(path);
  }

  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={routeChange} ><IntlMessages id="main.account" /></li>
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="main.logout" />
      </li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"/frontend/assets/images/avatar_1280.png"}
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{uname}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
