import { Row, Typography, Col, Button, Pagination, Spin } from "antd";
import { getFields, getRoom } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddRoom from "./addRoom";
import EditRoom from "./editRoom";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";

const { Text } = Typography;

export default function Room(props) {
  const { setMainPage, mainStates, setMainStates } = props;
  const [room, setRoom] = useState([]);
  const [addRoom, setAddRoom] = useState(false);
  const [editRoom, setEditRoom] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [fields, setFields] = useState({});

  useEffect(async () => {
    setLoad(true);
    const room_ = await getRoom((page - 1) * 10, mainStates?.floor);
    if (room_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    const cleaning_prg = await getFields("G01C04D00F21");
    const check_interval = await getFields("G01C04D00F26");
    const coverage_type = await getFields("G01C04D00F20");
    const flooring = await getFields("G01C04D00F15");
    const windowType = await getFields("G01C04D00F14");
    const costcenter = await getFields("G01C04D00F13");
    setFields({
      cleaning_program: cleaning_prg,
      check_interval: check_interval,
      coverage_type: coverage_type,
      flooring: flooring,
      windowType: windowType,
      costcenter: costcenter,
    });

    setRoom(room_?.list ? room_?.list : []);
    setCount(room_?.count ? room_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  return (
    <>
      <AddRoom
        open={addRoom}
        setOpen={setAddRoom}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={mainStates?.floor}
        fields={fields}
      />
      <EditRoom
        room={editRoom}
        setRoom={setEditRoom}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        fields={fields}
      />
      <Text
        style={{
          color: "#024442",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(1);
          }}
        >
          {mainStates?.estateName ? (
            mainStates?.estateName
          ) : (
            <IntlMessages id="estate.title" />
          )}
        </span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(2);
          }}
        >
          {` -> ${
            mainStates?.buildingName ? (
              mainStates?.buildingName
            ) : (
              <IntlMessages id="building.title" />
            )
          }`}
        </span>
        <span
          onClick={() => {
            setMainPage(3);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {` -> ${
            mainStates?.floorName ? (
              mainStates?.floorName
            ) : (
              <IntlMessages id="estate.title" />
            )
          } -> `}
        </span>
        <span>
          <IntlMessages id="room.title" />
        </span>
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load > 0 ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="room.title" />
              </Text>
            </Col>
            <Col xs={12} sm={12} xl={6}>
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {room?.map((room) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditRoom(room);
                  }}
                >
                  <Text
                    style={{
                      color: "#024442",
                    }}
                  >
                   {room?.cfeld1} {room?.cfeld2}
                    
                  </Text>
                </Col>

                <Col>
                  <MoreActions state={room} />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#024442",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>

                <Col>
                  <RightSquareOutlined
                    style={{
                      color: "#024442",
                      paddingLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      setMainPage(5);
                      setMainStates({
                        ...mainStates,
                        room: room?.stkey,
                        roomName: room?.cfeld2,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddRoom(!addRoom);
              }}
            >
              <IntlMessages id="room.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
