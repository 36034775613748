import {
  Row,
  Col,
  Button,
  Upload,
  message,
  Popconfirm,
  Popover,
  Typography,
} from "antd";
import IntlMessages from "util/IntlMessages";
import { DownOutlined } from "@ant-design/icons";

const { Text } = Typography;
const PhotoAction = (props) => {
  const {
    open,
    setOpen,
    deleteImage,
    pictures,
    setPhoto,
    uploadProps,
    cameraOpen,
    setCameraOpen,
    isModalVisible,
    setIsModalVisible,
  } = props;
  return (
    <>
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
          margin: "20px 0 0 0",
        }}
      >
        <Col
          xs={12}
          sm={12}
          xl={18}
          style={{
            padding: 0,
          }}
        >
          <Text>Name</Text>
        </Col>
        <Col xs={12} sm={12} xl={6}>
          <Text>
            {" "}
            <IntlMessages id="actions" />
          </Text>
        </Col>
      </Row>
      {pictures?.map((picture) => {
        return (
          <Row
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eaeaea",
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                setPhoto(picture);
              }}
            >
              <Text
                style={{
                  color: "#024442",
                }}
              >
                {picture?.cfeld1}
              </Text>
            </Col>

            <Col>
              <Text
                style={{
                  color: "#024442",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen(picture);
                }}
              >
                <IntlMessages id="more_actions" />
                {picture?.stkey === open?.stkey ? (
                  <Popover
                    content={
                      <Popconfirm
                        placement="bottomLeft"
                        title={"Do you really want to delete the Picture?"}
                        onConfirm={async () => {
                          const del = await deleteImage({
                            stkey: picture?.stkey,
                            cfeld1: picture?.cfeld1,
                          });
                          if (del?.success === "deleted") {
                            message.success("Image deleted Successfully");
                          } else {
                            message.error(
                              "An error occurred while performing action"
                            );
                          }

                          setOpen(null);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Text
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Delete
                        </Text>
                      </Popconfirm>
                    }
                    trigger="click"
                    visible={open}
                    onVisibleChange={() => {
                      setOpen(!open);
                    }}
                  ></Popover>
                ) : null}
              </Text>
              <DownOutlined
                size="small"
                style={{
                  color: "#024442",
                  paddingLeft: "10px",
                  fontSize: "10px",
                }}
              />
            </Col>
          </Row>
        );
      })}
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
          margin: "0",
        }}
        justify="end"
      >
        <Upload {...uploadProps}>
          <Button
            size="small"
            type="primary"
            style={{
              margin: 0,
            }}
            onClick={() => {}}
          >
            add from files
          </Button>
        </Upload>
        <Button
          size="small"
          type="primary"
          style={{
            margin: "0 0 0 15px",
          }}
          onClick={() => {
            setCameraOpen(!cameraOpen);
            setIsModalVisible(!isModalVisible);
          }}
        >
          add from camera
        </Button>
      </Row>
    </>
  );
};

export default PhotoAction;
