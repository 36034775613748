import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./Topbar/languageData";
import {switchLanguage} from "../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";

const LanguageMenu = () => {

    const dispatch = useDispatch();
    const {locale} = useSelector(({settings}) => settings);
    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll" >
        <ul className="gx-sub-popover">      
            {languageData.map(language =>
            <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() =>
                dispatch(switchLanguage(language))
            }>
                <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
                <span className="gx-language-text">{language.name}</span>
            </li>
            )}
        </ul>
        </CustomScrollbars>
    );

  return (  <div  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
  }}>
          <ul className="gx-header-notifications gx-ml-auto">
          <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                     content={languageMenu()} trigger="click">
          <span className="gx-pointer gx-flex-row gx-align-items-center"><i
            className={`flag flag-24 flag-${locale.icon}`}/>
          </span>
            </Popover>
            </li>
            </ul>
            </div>)

        }

        export default LanguageMenu;

