import React, { useEffect } from "react";
import { Button, Input, message, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import LanguageMenu from "./LanguageMenu";
import CircularProgress from "../components/CircularProgress";

const SignIn = () => {
  const dispatch = useDispatch();
  
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    if (showMessage) {
      
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser != null && authUser != undefined) {
      history.push("/");
    }
  });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   props.form.validateFields((err, values) => {
  //     if (!err) {
  //       dispatch(showAuthLoader());
  //       dispatch(userSignIn(values));
  //     }
  //   });
  // };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log("finish", values);
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src={"/assets/images/272x395blue.png"} alt="Neature" /> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>              
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/frontend/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              
              <Form.Item
                
                rules={[
                  { required: true, message: intl.formatMessage({ id: "app.userAuth.enterUsername" }) },
                ]}
                name="email"
              >
                <Input placeholder={intl.formatMessage({ id: "app.userAuth.username" })} />
              </Form.Item>
              <Form.Item
                
                rules={[
                  { required: true, message: intl.formatMessage({ id: "app.userAuth.enterPassword" }) },
                ]}
                name="password"
              >
                <Input.Password type="password" placeholder={intl.formatMessage({ id: "app.userAuth.password" })} />
              </Form.Item>

             
              <Form.Item>
                <table border="0">
                  <tr>
                    <td>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>                
                </td>
                <td >
                  &nbsp;
                </td>
                <td >
                <Link style={{textDecoration: 'underline'}} to="/signup"><IntlMessages id="appModule.regsiter"/></Link><br />
                <Link style={{textDecoration: 'underline'}} to="/forgotpassword"><IntlMessages id="app.userAuth.forgotPassword"/></Link>
                </td>
                </tr>
                </table>
              </Form.Item>
             <LanguageMenu/>
            </Form>
            
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}

          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
