import {
  Row,
  Typography,
  Col,  
  Spin,
  Table,
  Badge,
  Popconfirm,
  message,
  Input,
  Card,
  Button,
  Modal,
  Form,
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import { getUsername, changePassword, deleteUser } from "./network_requests";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import IntlMessages from "util/IntlMessages";

const { Text } = Typography;

export default function ChangePassword(props) {

  const [load, setLoad] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const [addUser, setAddUser] = useState(false);

  const [username, setusername] = useState('');

  useEffect(async () => {
    setLoad(true);

    const result_ = await getUsername();
    
    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setusername(result_);

    setLoad(false);
  }, []);

  async function confirm(e) {
    
    setLoad(true);

    const delResult = await deleteUser();  
    
    setLoad(false);

    localStorage.removeItem("user_id");
    history.go(0);
  }

  async function sendAsync(oldpassword, password) {
    setLoad(true);

    const result_ = await changePassword(oldpassword, password);

    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 
    if (result_) {
      if (result_ == "success") {
        
        Modal.success({
          title: intl.formatMessage({ id: "main.account" }),
          content: intl.formatMessage({ id: "changedPassword.success" }),
        });
      } else if (result_ == "error") {
        Modal.error({
          title: intl.formatMessage({ id: "error" }),
          content: intl.formatMessage({ id: "changedPassword.error" }),
        });
      } else if (result_ == "oldPwError") {
        Modal.error({
          title: intl.formatMessage({ id: "error" }),
          content: intl.formatMessage({ id: "changedPassword.oldPwError" }),
        });
      } else {
        Modal.info({
          title: intl.formatMessage({ id: "main.account" }),
          content: result_,
        });
      }
    } 
    setLoad(false); 
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    
    if (values.newpassword != values.newpassword2) {

      Modal.error({
        title: intl.formatMessage({ id: "error" }),
        content: intl.formatMessage({ id: "app.userAuth.passwordsDontMatch" }),
      });
      document.getElementById("changePasswordForm").reset();
      return;
    }

    sendAsync(values.oldpassword, values.newpassword);

    document.getElementById("changePasswordForm").reset();
  };
  
  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      ) : null}
      {!load ? (
        <>
<Card className="gx-card" title={intl.formatMessage({ id: "main.account" })} >
     <Text><IntlMessages id="app.userAuth.username"/>:  {username}</Text>  
     
<br/>
<br/>
     <Form
              name="basic"
              id="changePasswordForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
     <Row>
            <Col lg={8} md={12} sm={24} xs={24} >
            <Form.Item
                
                rules={[
                  { required: true, message: intl.formatMessage({ id: "app.userAuth.enterPassword" }) },
                ]}
                name="oldpassword"
              >
            <Input.Password type="password" placeholder={intl.formatMessage({ id: "app.userAuth.oldpassword" })} />  
            </Form.Item>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            
            </Col>
          </Row>
<Row>
            
            <Col lg={8} md={12} sm={24} xs={24} >
            <Form.Item
                
                rules={[
                  { required: true, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/ , message: intl.formatMessage({ id: "app.userAuth.enterPasswordFormat" }) },
                ]}
                name="newpassword"
              >
            <Input.Password type="password" placeholder={intl.formatMessage({ id: "app.userAuth.newpassword" })} />         
            </Form.Item>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            
            </Col>
          </Row>
<Row>
            <Col lg={8} md={12} sm={24} xs={24} >
            <Form.Item
                dependencies={['newpassword']} hasFeedback
                rules={[
                  { required: true, message: intl.formatMessage({ id: "app.userAuth.enterPassword" }) },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newpassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(intl.formatMessage({ id: "app.userAuth.passwordsDontMatch" })));
                    },
                  })
                ]}
                name="newpassword2"
              >
            <Input.Password type="password" placeholder={intl.formatMessage({ id: "app.userAuth.reenterpassword" })} />
            </Form.Item>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            </Col>
            <Col lg={8} md={6} sm={0} xs={0}>
            
            </Col>
          </Row>
<Form.Item>
      <Button
                type="primary" className="gx-mb-0" htmlType="submit"
              >
                <IntlMessages id="save"/>
              </Button>
                            
              </Form.Item>
              </Form>

              <Popconfirm title={intl.formatMessage({ id: "user.delete" })} onConfirm={() => {
      confirm();
    }} okText={intl.formatMessage({ id: "confirm.yes" })} cancelText={intl.formatMessage({ id: "confirm.no" })}>
        <Button              
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                
              }} >
              <IntlMessages id="user.delete.btn" />
              </Button>
      </Popconfirm>
              
    </Card>
        </>
      ) : null}
    </>
  );
}
