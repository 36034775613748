import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import Icon from '@ant-design/icons';
import {Link, useHistory} from "react-router-dom";
import { useIntl } from "react-intl";
import LanguageMenu from "./LanguageMenu";

import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userSignUp,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";

const FormItem = Form.Item;

const SignUp = (props) => {

  const {locale} = useSelector(({settings}) => settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const intl = useIntl();

  useEffect(() => {
    //console.log('useeffect');
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 500);
    }
    /*if (authUser !== null) {
      history.push('/');
    }*/
  });

  /*
  const handleSubmit = (e) => {
    console.log("test");
    e.preventDefault();
    props.form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        dispatch(showAuthLoader());
        dispatch(userSignUp(values));
      }
    });
  }; */

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    
    var clone = { ...values };
    clone.locale = locale.locale;

    dispatch(showAuthLoader());
    dispatch(userSignUp(clone));
    document.getElementById("registerForm").reset();
  };

  function displayMessage(msg) {

    if (msg == "1") {
      message.info(intl.formatMessage({ id: "app.userAuth.registerSuccess" }));
    }  else if (msg == "wp") {
      message.error(intl.formatMessage({ id: "app.userAuth.passwordsDontMatch" }));
    } else if (msg == "ue") {
      message.error(intl.formatMessage({ id: "app.userAuth.registerError" }));
    } else {
      message.error(msg);
    }
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signUp"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/frontend/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              id="registerForm"
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <FormItem rules={[{required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,   message: intl.formatMessage({ id: "app.userAuth.enterUsername" }) }]} name="Username">
                  <Input placeholder={intl.formatMessage({ id: "app.userAuth.username" })} />
              </FormItem>
              <FormItem name="password1" rules={[
                {required: true, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/ , message: intl.formatMessage({ id: "app.userAuth.enterPasswordFormat" })}
                ]}>
                  <Input.Password type="password" placeholder={intl.formatMessage({ id: "app.userAuth.password" })} />
              </FormItem>
              <FormItem name="password2" dependencies={['password1']} hasFeedback
                        rules={[
                          {required: true, message: intl.formatMessage({ id: "app.userAuth.enterPassword" }) },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password1') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(intl.formatMessage({ id: "app.userAuth.passwordsDontMatch" })));
                            },
                          })
                          ]}>
                  <Input.Password type="password" placeholder={intl.formatMessage({ id: "app.userAuth.reenterpassword" })}/>
              </FormItem>
              <FormItem>
              <table border="0">
                  <tr>
                    <td>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp"/>
                </Button>
                </td>
                <td >
                  &nbsp;
                </td>
                <td >
                <Link style={{textDecoration: 'underline'}} to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link><br />
                <Link style={{textDecoration: 'underline'}} to="/forgotpassword"><IntlMessages id="app.userAuth.forgotPassword"/></Link>
                </td>
                </tr>
                </table>
              </FormItem>
              <LanguageMenu/>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
           {showMessage ?  displayMessage(alertMessage.toString())  : null}
        </div>
      </div>
    </div>
  );
};


export default SignUp;
