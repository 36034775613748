import {
  Row,
  Typography,
  Col,  
  Spin,
  Table,
  Badge,
  Popconfirm,
  message,
  Card
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import { getLinks, deleteLink } from "./network_requests";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";

const { Text } = Typography;

export default function LinkOverview(props) {

  const [load, setLoad] = useState(false);
  const history = useHistory();

  const intl = useIntl();

  const [links, setlinks] = useState([]);

  useEffect(async () => {
    setLoad(true);

    const result_ = await getLinks();
    console.log(result_);
    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setlinks(result_);

    setLoad(false);
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return new Date(dateString).toLocaleDateString(intl.locale, options)
  }

  async function confirm(e) {
    
    setLoad(true);

    const delResult = await deleteLink(e);
    
    const result_ = await getLinks();
    
    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setlinks(result_);

    setLoad(false);

  }

  const columns = [
    {
      title: intl.formatMessage({ id: "device.id" }),
      dataIndex: 'deviceId',
      key: 'deviceId',
      sorter: (a, b) => {  return a.deviceId - b.deviceId; },
    },
    {
      title: intl.formatMessage({ id: "device.name" }),
      dataIndex: 'deviceName',
      key: 'deviceName',
      sorter: (a, b) => {  return a.deviceName.localeCompare(b.deviceName); },
    },
    {
      title: intl.formatMessage({ id: "device.type" }),
      dataIndex: 'deviceType',
      key: 'deviceType',
      sorter: (a, b) => {  return a.deviceType.localeCompare(b.deviceType); },
    },
    {
      title: intl.formatMessage({ id: "createdAt" }),
      dataIndex: 'created',
      key: 'created',
      render: text => <text>{formatDate(text)}</text>,
      sorter: (a, b) => {  return new Date(a.created) - new Date(b.created); },
    },
    {
      title: 'Online',
      dataIndex: 'online',
      key: 'online',
      render: text => text ? <Badge status="success" /> : <Badge status="error" />,
    }, {
      title: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <span>

      <Popconfirm title={intl.formatMessage({ id: "link.delete" })} onConfirm={() => {
      confirm(text.deviceId);
    }} okText={intl.formatMessage({ id: "confirm.yes" })} cancelText={intl.formatMessage({ id: "confirm.no" })}>
        <span className="gx-link"><i className="icon icon-trash"/></span>
      </Popconfirm>

        </span>
      ),
    }
  ];


  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      ) : null}
      {!load ? (
        <>
<Card className="gx-card" title={intl.formatMessage({ id: "LinkOverview.title" })}>
        <Table className="gx-table-responsive" columns={columns} dataSource={links}/>
        </Card>
        </>
      ) : null}
    </>
  );
}
