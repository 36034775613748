import { Modal, Typography, Row, Col, Pagination, Spin, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { editLov, getLovbyID } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import AddLov from "./addLov";
import EditLov from "./editLov";

const { Text } = Typography;

export default function EditList(props) {
  const { lov, setLov } = props;
  const open = Boolean(lov);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const ref = useRef();
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openLov, setOpenLov] = useState(null);

  useEffect(async () => {
    setLoad(true);
    const lov_ = await getLovbyID({
      lovgrkey: lov?.lovgrkey,
    });

    if (lov_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setData(lov_ ? lov_ : []);
    setCount(lov_?.length ? lov_?.length : 0);
    setLoad(false);
  }, [lov, update]);

  return (
    <>
      <AddLov
        open={openAdd}
        setOpen={setOpenAdd}
        update={update}
        setUpdate={setUpdate}
        parent={lov}
        setLoad={setLoad}
      ></AddLov>
      <EditLov
        lov={openLov}
        setLov={setOpenLov}
        update={update}
        setUpdate={setUpdate}
        parent={lov}
        setLoad={setLoad}
      ></EditLov>
      <Modal
        title={
          <>
            <IntlMessages id="add" />
          </>
        }
        visible={open}
        centered
        destroyOnClose
        okText="Save"
        onOk={() => {
          setLov(null);
          ref?.current?.submit();
        }}
        onCancel={() => {
          setLov(null);
        }}
      >
        {load ? (
          <div
            style={{
              marginTop: "2vh",
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Spin />
          </div>
        ) : null}

        {!load ? (
          <>
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "20px 0 0 0",
                width: "100%",
              }}
            >
              <Col
                span={8}
                style={{
                  padding: 0,
                }}
              >
                <Text>Name</Text>
              </Col>
              <Col span={8}>
                <Text>Sequence</Text>
              </Col>
              <Col span={8}>
                <Text>Comment</Text>
              </Col>
            </Row>

            {data.slice((page - 1) * 10, page * 10)?.map((listValue) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col
                    span={8}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenLov(listValue);
                    }}
                  >
                    <Text
                      style={{
                        color: "#024442",
                      }}
                    >
                      {listValue?.lovvalue}
                    </Text>
                  </Col>

                  <Col span={8}>
                    <Text
                      style={{
                        color: "#024442",
                      }}
                    >
                      {listValue?.lovorder}
                    </Text>
                  </Col>

                  <Col span={8}>
                    <Text
                      style={{
                        color: "#024442",
                      }}
                    >
                      {listValue?.lovcomment}
                    </Text>
                  </Col>
                </Row>
              );
            })}

            <Row
              style={{
                backgroundColor: "#FAFAFA",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Button
                type="primary"
                onClick={() => {
                  setOpenAdd(!openAdd);
                }}
              >
                Add Value
              </Button>
            </Row>

            <Row
              style={{
                backgroundColor: "#fff",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                defaultPageSize={10}
                style={{
                  margin: 0,
                }}
                onChange={(newpage) => {
                  setPage(newpage);
                }}
              />
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  );
}
