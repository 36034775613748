import { url } from "../../../constants";
import axios from "axios";

export const getUsername = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.get(
      `${url}getUsername`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const changePassword = async (oldpassword, newpassword) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}changepassword`,
      {
        deviceId: oldpassword,
        deviceName: newpassword
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteUser = async (id) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.delete(
      `${url}deleteUser/`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
