import { url } from "../../../constants";
import axios from "axios";

export const linkDevice = async (deviceId, linkKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}link`,
      {
        deviceId: deviceId,
        linkKey: linkKey
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getLink = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.get(
      `${url}link`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};