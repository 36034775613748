import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Form,
  Input,
  Popover,
  Popconfirm,
  Modal,
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";

const { Text } = Typography;

export default function PrivacyStatement(props) {
  const [load, setLoad] = useState(false);

  useEffect(async () => {
    setLoad(true);

    setLoad(false);    
  }, []);

  return (
  <>
  {load ? (
    <div
      style={{
        marginTop: "10vh",
        justifyContent: "center",
        display: "flex",
        width: "100%",
      }}
    >
      <Spin tip="Loading..." />
    </div>
  ) : null}
  {!load ? (
    <div>
        <h1><IntlMessages id="privacyStatement.1" /></h1><br /><br />
        <strong><IntlMessages id="privacyStatement.2" /></strong><br />
        <IntlMessages id="privacyStatement.3" /><br /><br />
        <strong><IntlMessages id="privacyStatement.4" /></strong><br />
        <IntlMessages id="privacyStatement.5" /><br />
        <IntlMessages id="privacyStatement.6" /><br /><br />
        <strong><IntlMessages id="privacyStatement.7" /></strong><br />
        <IntlMessages id="privacyStatement.8" /><br /><br />
        <IntlMessages id="privacyStatement.9" /><br /><br />
        <strong><IntlMessages id="privacyStatement.10" /></strong><br />
        <IntlMessages id="privacyStatement.11" /><br />
        <IntlMessages id="privacyStatement.12" /><br /><br />
        <strong><IntlMessages id="privacyStatement.13" /></strong><br />
        <IntlMessages id="privacyStatement.14" /><br />
        <IntlMessages id="privacyStatement.15" /><br />
        <IntlMessages id="privacyStatement.16" /><br />
        <IntlMessages id="privacyStatement.17" /><br /><br />
        <strong><IntlMessages id="privacyStatement.18" /></strong><br />
        <IntlMessages id="privacyStatement.19" /><br />
        <IntlMessages id="privacyStatement.20" /><br /><br />
        <strong><IntlMessages id="privacyStatement.21" /></strong><br />
        <IntlMessages id="privacyStatement.22" /><br /><br />
        </div>
  ) : null }
</>
);
}
