import { url } from "../../../constants";
import axios from "axios";

export const getWhitelabeledDevices = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.get(
      `${url}whitelabeledDevices`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteWhitelabeledDevice = async (deviceId) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.delete(
      `${url}whitelabeledDevice/`+deviceId,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addWhitelabeledDevice = async (deviceIds) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}whitelabeledDevice`,
      {
        deviceId: deviceIds
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
