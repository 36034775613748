import {
  Row,
  Typography,
  Col,  
  Spin,
  Table,
  Badge,
  Popconfirm,
  message,
  Input,
  Card,
  Button,
} from "antd";
import React from 'react';
import { useEffect, useState } from "react";
import { getWhitelabeledDevices, deleteWhitelabeledDevice, addWhitelabeledDevice } from "./network_requests";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import IntlMessages from "util/IntlMessages";

const { Text } = Typography;

export default function WhitelabeledDevice(props) {

  const [load, setLoad] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const [links, setlinks] = useState([]);
  const [deviceIds, setDeviceIds] = useState('');
  const {TextArea} = Input;

  useEffect(async () => {
    setLoad(true);

    const result_ = await getWhitelabeledDevices();
    
    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setlinks(result_);

    setLoad(false);
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  async function confirm(e) {
    
    setLoad(true);

    const delResult = await deleteWhitelabeledDevice(e);
    
    const result_ = await getWhitelabeledDevices();
    
    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setlinks(result_);

    setLoad(false);

  }

  const columns = [
    {
      title: intl.formatMessage({ id: "device.id" }),
      dataIndex: 'deviceId',
      key: 'deviceId',
      sorter: (a, b) => {  return a.deviceId - b.deviceId; },
    },
    {
      title: intl.formatMessage({ id: "createdAt" }),
      dataIndex: 'created',
      key: 'created',
      render: text => <text>{formatDate(text)}</text>,
      sorter: (a, b) => {  return new Date(a.created) - new Date(b.created); },
    },
    {
      title: 'Online',
      dataIndex: 'online',
      key: 'online',
      render: text => text ? <Badge status="success" /> : <Badge status="error" />,
    }, {
      title: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <span>

      <Popconfirm title={intl.formatMessage({ id: "link.delete" })} onConfirm={() => {
      confirm(text.deviceId);
    }} okText={intl.formatMessage({ id: "confirm.yes" })} cancelText={intl.formatMessage({ id: "confirm.no" })}>
        <span className="gx-link"><i className="icon icon-trash"/></span>
      </Popconfirm>

        </span>
      ),
    }
  ];

  async function sendAsync() {
    setLoad(true);
    
    const result_ = await addWhitelabeledDevice(deviceIds);

    if (result_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 
    if (result_) {
      setDeviceIds("");
    }

    const result2_ = await getWhitelabeledDevices();
    
    if (result2_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    } 

    setlinks(result2_);
    
    setLoad(false);
  }

  const updateDeviceIdValue = (evt) => {
    setDeviceIds(evt.target.value);
  };

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      ) : null}
      {!load ? (
        <>
<Card className="gx-card" title={intl.formatMessage({ id: "addWhitelabeledDevice" })}>
      <TextArea rows={10} value={deviceIds} onChange={(event) => updateDeviceIdValue(event)} />
      <Button
                size="small"
                type="primary"
                style={{
                  margin: 0,
                }}
                onClick={() => {
                  sendAsync();
                }}
              >
                <IntlMessages id="add"/>
              </Button>
    </Card>
    <Card className="gx-card" title={intl.formatMessage({ id: "whitelabeledDevice" })}>
<Table className="gx-table-responsive" columns={columns} dataSource={links}/>
</Card>
        </>
      ) : null}
    </>
  );
}
